<template>
  <div class="block-card max-h-[80vh] bg-cover lg:mb-[-10px] !justify-start" :style="{
          'background-image': isLargeScreen ? 'url(../images/main1-desktop.png)' : 'url(../images/main1-mobile.png)',
          'background-position': 'center'
      }" >
    <div
        :data-aos="isLargeScreen ? 'fade-in' : false"
        :data-aos-delay="isLargeScreen ? 1500 : 0"
        :data-aos-duration="isLargeScreen ? 1000 : 0"
        class="w-[80%] max-w-[300px] m-x-auto text-center"
    >
      <img
          src="images/initials.png"
          class="lg:h-auto h-[80px] m-auto"
      />
    </div>
    <div
        :data-aos="isLargeScreen ? 'fade-up' : false"
        :data-aos-delay="isLargeScreen ? 1500 : 0"
        :data-aos-duration="isLargeScreen ? 1000 : 0"
        class="max-w-[400px] w-[80%] m-x-auto lg:mt-4 mt-2"
        :class="{
          'mt-4': isLargeScreen
        }"
    >
      <img
          src="images/names.png"
          class="lg:h-auto h-[80px] m-auto"
      />
    </div>
  </div>

  <div class=" overflow-auto test" :style="isLargeScreen ? '--background-url: url(../images/main2.png)' : '--background-url: url(../images/main2-mob.png)'">
    <div class="lg:max-w-[80%] max-w-[90%] bg-center bg-cover m-auto relative rounded-xl"
         :style="{
            'background-image': 'url(../images/Layer_1_1.png)'
         }"
    >
      <div class="block-card">
        <h2
            class="leading-snug lg:text-[45px] text-[30px] uppercase text-center m-x-auto font-playfair"
            data-aos="fade-in"
            data-aos-duration="1000"
        >Дорогие наши <br> родные и друзья!</h2>

        <div data-aos="fade-up"
             data-aos-duration="1000"
             data-aos-delay="500"
             class="mt-[40px] w-[80%] max-w-[600px] text-xl"
        >
          Совсем скоро наступит день, который будет для нас особенным – <strong>день нашей свадьбы!</strong><br>
          Мы очень хотим, чтобы рядом были близкие и родные люди и приглашаем Вас провести этот день вместе с нами. <br/>
          Давайте создадим вместе незабываемые моменты, наполненные теплом, улыбками и волшебством.
        </div>
      </div>
      <div class="block-card">
        <h2
            class="leading-snug lg:text-[45px] text-[30px] uppercase text-center m-x-auto font-playfair"
            data-aos="fade-in"
            data-aos-duration="1000"
        >План мероприятия</h2>
        <img
            :src="'../images/date.png'"
            class="w-[80%] max-w-[150px] mx-auto my-[30px]"
            :data-aos="isLargeScreen ? 'fade-in' : false"
            :data-aos-duration="isLargeScreen ? 1000 : 0"
        />
        <div
            class="flex gap-10 items-center justify-center max-w-[800px] w-[80%] mt-8 text-l mb-[50px]"
            :class="{
              'flex-col': !isLargeScreen
             }"
        >
          <div class="flex flex-col gap-6 items-center max-w-[170px]"
               :data-aos="isLargeScreen ? 'fade-left' : false"
               :data-aos-duration="isLargeScreen ? 400 : 0"
               :data-aos-delay="isLargeScreen ? 1000 : 0"
          >
            <img
                class="max-w-[100px] lg:max-w-[150px] object-contain" :src="'../images/icons/wedding-location.png'" />
            <div class="h-[80px]">
              15:30 <br>
              Сбор гостей/фуршет
            </div>
          </div>
          <div class="flex flex-col gap-6 items-center max-w-[170px]"
               :data-aos="isLargeScreen ? 'fade-left' : false"
               :data-aos-duration="isLargeScreen ? 400 : 0"
               :data-aos-delay="isLargeScreen ? 1400 : 0"
          >
            <img
                class="max-w-[100px] lg:max-w-[150px] object-contain" :src="'../images/icons/wedding.png'" />
            <div class="h-[80px]">
              16:00 <br>
              Церемония выездной регистрации
            </div>
          </div>
          <div class="flex flex-col gap-6 items-center max-w-[170px]"
               :data-aos="isLargeScreen ? 'fade-left' : false"
               :data-aos-duration="isLargeScreen ? 400 : 0"
               :data-aos-delay="isLargeScreen ? 1800 : 0"
          >
            <img
                class="max-w-[100px] lg:max-w-[150px] object-contain" :src="'../images/icons/romantic-dinner.png'" />
            <div class="h-[80px]">
              17:00 <br>
              Банкет
            </div>
          </div>
          <div class="flex flex-col gap-6 items-center max-w-[170px]"
               :data-aos="isLargeScreen ? 'fade-left' : false"
               :data-aos-duration="isLargeScreen ? 400 : 0"
               :data-aos-delay="isLargeScreen ? 2200 : 0"
          >
            <img
                class="max-w-[100px] lg:max-w-[150px] object-contain"  :src="'../images/icons/cake.png'" />
            <div class="h-[80px]">
              22.00 <br>
              Вынос торта
            </div>
          </div>
        </div>

        <img
            :src="'../images/date2.png'"
            class="w-[80%] max-w-[150px] mx-auto my-[30px]"
            :data-aos="isLargeScreen ? 'fade-in' : false"
            :data-aos-duration="isLargeScreen ? 1000 : 0"
            :data-aos-delay="isLargeScreen ? 2200 : 0"
        >
        <div
            class="flex gap-16 items-center justify-center max-w-[800px] w-[80%] mt-8 text-lg mb-[50px]"
            :class="{
              'flex-col': !isLargeScreen
             }"
        >
          <div class="flex flex-col gap-6 items-center text-center"
               :data-aos="isLargeScreen ? 'fade-left' : false"
               :data-aos-duration="isLargeScreen ? 400 : 0"
               :data-aos-delay="isLargeScreen ? 2400 : 0"
          >
            <img class="max-w-[100px] lg:max-w-[150px] object-contain" :src="'../images/icons/disco.png'" />
            <div>
              12:00 <br>
              Второй день свадьбы
            </div>
          </div>
        </div>
      </div>
      <div class="block-card">
        <h2
            class="leading-snug lg:text-[45px] text-[30px] uppercase text-center m-x-auto font-playfair"
            :data-aos="isLargeScreen ? 'fade-in' : false"
            :data-aos-duration="isLargeScreen ? 1000 : 0"
        >Место проведения</h2>

        <div :data-aos="isLargeScreen ? 'fade-up' : false"
             :data-aos-duration="isLargeScreen ? 1000 : 0"
             :data-aos-delay="isLargeScreen ? 1000 : 0"
             class="mt-[40px] mb-[40px] w-[80%] max-w-[600px] text-xl"
        >
          Наша свадьба будет проходить в шатре <strong>White Park</strong>, который находится в 3 км от города Лида, аг. Ёдки, улица Придорожная, 30
        </div>
        <div
            :data-aos="isLargeScreen ? 'fade-up' : false"
            :data-aos-duration="isLargeScreen ? 2000 : 0"
            :data-aos-delay="isLargeScreen ? 1000 : 0"
        >

          <div style="position:relative;overflow:hidden;">
            <a href="https://yandex.by/maps/29634/grodno-district/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Гродненская область</a><a href="https://yandex.by/maps/29634/grodno-district/house/ZkwYdQRoTEUGQFtpfXRycH1mbA==/?ll=25.338501%2C53.831159&utm_medium=mapframe&utm_source=maps&z=16" style="color:#eee;font-size:12px;position:absolute;top:14px;">Придорожная улица, 30 на карте Гродненской области — Яндекс Карты</a><iframe src="https://yandex.by/map-widget/v1/?ll=25.338501%2C53.831159&mode=whatshere&whatshere%5Bpoint%5D=25.338501%2C53.831158&whatshere%5Bzoom%5D=17&z=16" :width="isLargeScreen ? 500 : 300"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    :height="isLargeScreen ? 400 : 300" frameborder="0" style="position:relative;"></iframe>
          </div>
        </div>
      </div>

      <div class="block-card">
        <h2
            class="leading-snug lg:text-[45px] text-[30px] uppercase text-center m-x-auto font-playfair"
            data-aos="fade-in"
            data-aos-duration="1000"
        >Дресс-код</h2>

        <div
            class="mt-[40px] w-[80%] max-w-[600px] text-xl"
        >
          Мы приложили максимум усилий, чтобы создать прекрасную атмосферу на празднике и будем очень рады, если вы поддержите общую цветовую гамму нашей свадьбы, используя пастельные оттенки в вашем наряде, избегая ярких цветов.
          <br> Будем признательны, если образ – «Total White» будет только у невесты &#128522;
        </div>
        <div class="flex items-center gap-1 mt-8"
             :class="{
              'flex-wrap justify-center gap-x-4 gap-y-2': !isLargeScreen
             }"
        >
          <img class="max-w-[100px]" :src="'../images/color1.png'" />
          <img class="max-w-[100px]" :src="'../images/color2.png'" />
          <img class="max-w-[100px]" :src="'../images/color3.png'" />
          <img class="max-w-[100px]" :src="'../images/color4.png'" />
          <img class="max-w-[100px]" :src="'../images/color5.png'" />
          <img class="max-w-[100px]" :src="'../images/color6.png'" />
        </div>
        <img :src="'../images/color-scheme.JPG'" class="h-auto w-full mt-8">
      </div>
      <div class="block-card">
        <h2
            class="leading-snug lg:text-[45px] text-[30px] uppercase text-center m-x-auto font-playfair"
            :data-aos="isLargeScreen ? 'fade-in' : false"
            :data-aos-duration="isLargeScreen ? 1000 : 0"
        >Детали</h2>
        <div :data-aos="isLargeScreen ? 'fade-up' : false"
             data-aos-duration="1000"
             data-aos-delay="1000"
             class="mt-[40px] w-[80%] max-w-[600px] text-xl"
        >
          Если вы захотите подарить нам букет, то предлагаем не мучить цветы долгой дорогой, ведь на свадьбе будет присутствовать живая флористика.<br>
          Лучше заменить их бутылочкой любого приятного напитка, которую мы откроем на ближайшем семейном празднике.
        </div>
        <img class="max-w-[100px] lg:max-w-[150px] object-contain mt-6" :src="'../images/icons/champaign.png'">
      </div>
      <div class="block-card">
        <h2
            class="leading-snug lg:text-[45px] text-[30px] uppercase text-center m-x-auto font-playfair"
            data-aos="fade-in"
            data-aos-duration="1000"
        >Связь с координатором</h2>
        <div data-aos="fade-up"
             data-aos-duration="1000"
             data-aos-delay="1000"
             class="mt-[40px] w-[80%] max-w-[600px] text-xl"
        >
          Если вы заблудились, готовите сюрприз или у вас появились какие-либо вопросы, вам с радостью поможет наш замечательный координатор –<br>
          Алена +375295800086
        </div>
      </div>
      <div class="block-card">
        <h1 class="text-2xl font-bold max-w-[600px] w-[80%]">Пожалуйста, чтобы всё прошло идеально, ответьте на несколько вопросов в анкете:</h1>
        <div class="my-6 text-xl"> Просьба заполнить данную анкету до <strong>05.05.2024</strong></div>
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeVLr217JfG6f5wK8eujomeTAWAgSFlcTkMUIidWN-_6VtS3Q/viewform?embedded=true"
            :width="isLargeScreen ? 640 : width * .9 + 'px'"
            height="1200"
            frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
    </div>
  </div>
  <div class="h-screen bg-center bg-cover flex justify-start flex-col items-center gap-8 pt-8" :style="{ 'background-image': isLargeScreen ? 'url(../images/waiting-desktop.png)' : 'url(../images/waiting-mobile.png)' }">
    <h1 class="uppercase px-4 lg:text-[35px] text-[25px]">С нетерпением ждем встречи с каждым из вас!</h1>
    <Countdown
        deadline="2024-05-18 00:16:00"
        :labels="{ days: 'Дней', hours: 'Часов', minutes: 'Минут', seconds: 'Секунд' }"
        labelColor="white"
        mainColor="white"
    />
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import AOS from "aos";
import { useMediaQuery } from '@vueuse/core'
import { Countdown } from 'vue3-flip-countdown'
import { useWindowSize } from '@vueuse/core'

onMounted(() => {
  AOS.init();
})

const isLargeScreen = useMediaQuery('(min-width: 1024px)')

const { width } = useWindowSize()

</script>
<style>

body {
  font-family: "Philosopher", sans-serif;
  height: 100vh;
}

.block-card {
  min-height: 100vh;
  padding: 40px 0;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.test {
  position: relative;
  overflow: hidden;
}

.test:before {
  content: "";
  position: fixed; /* This positions the pseudo-element relative to the viewport, which simulates a fixed background */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--background-url);
  background-size: cover;
  background-position: top;
  z-index: -1; /* Ensures the background is behind the content */
}

h1 {
  font-family: "Playfair Display", serif;
  text-align: center;
}
</style>

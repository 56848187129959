import { createWebHistory, createRouter } from 'vue-router'
import MainPage from '../components/MainPage'

const routes = [
  {
    path: '/wedding',
    name: 'Wedding',
    component: MainPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
